<template>
<div class="buydlog">
  <el-dialog
    v-model="centerDialogVisible"
    title="卖出"
    width="700px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    center
  >
    <el-icon class="closeIcon" @click="remove()"><Close /></el-icon>

    <div class="table_show">
      <el-scrollbar max-height="400px">
        <table id="tfhover" class="tftable">
          <tr>
            <th v-for="i in dataTitle" :key="i">
              {{i}}
            </th>
          </tr>
          <tr class="heng" v-for="(item, index) in listData" :key="index">
            <td>{{item.omsOrderItem.productName}}</td>
            <td>{{item.pmsSiteEntity.name}}</td>
            <td>{{'￥'+item.omsOrderItem.productPrice}}</td>
            <td>{{item.omsOrderItem.productQuantity-item.resaleNum}}
              <span class="saleitem">已转出({{item.resaleNum}})</span>
            </td>
            <td><span class="btnbuy" @click="selectBreedValue(item)">卖出</span></td>
          </tr>
          <div class="nodata" v-if="listData.length == 0">
            <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
          </div>
        </table>
      </el-scrollbar>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { ref, reactive } from 'vue'

import { Close } from '@element-plus/icons'
import http from '../api/http'

// import emitter from '../tools/bus.js'

export default {
  name: 'MessageBoxSale',
  data () {
    return {
      dataTitle: reactive(['品种', '仓库', '买入价格', '可卖数量', '操作']),
      listData: reactive([]), // 存储当前用户的商品
      user_id: ''
    }
  },
  components: {
    Close
  },
  methods: {
    // 根据table data传来的参数，发送请求  给listdata赋值
    showTableData () {
      const getdata = {
        // status: 32,
        // page: 1,
        // size: 100
      }
      http.get('/mall-portal/sso/getAllowResaleOrderList', getdata)
        .then(res => {
          // console.log(res.data.data)
          console.log(res)
          this.listData = res.data.data
        }).catch(err => {
          console.log(err)
        })
    },

    // 点击卖出时触发该方法
    selectBreedValue (e) {
      // console.log(e)
      // 先关闭当前的弹窗
      this.$emit('removethis', false)
      // 要打开的卖出弹窗
      this.$emit('toQuickSale', { goto: 'MessageBoxQuickSale', datalist: e })
    }
  },
  mounted () {
    this.showTableData()
    // this.saveBreed_name()
  },
  props: ['boxdata'],
  setup (props, ctx) {
    // console.log(props.boxdata)
    // const count = ref(0)
    // 窗口默认参数===不能更改删除
    var centerDialogVisible = ref(true)
    // const load = () => {
    //   count.value += 2
    // }

    // 关闭弹窗操作
    const remove = () => {
      ctx.emit('removethis', false)
    }
    return {
      centerDialogVisible,
      // load,
      remove
    }
  }
}
</script>
<style lang="scss">
.buydlog{
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        .el-dialog__header{
          height: 50px;
          padding: 0 20px;
          border-bottom: 1px solid #EAEAEA;
          .el-dialog__title{
            float: left;
            color: #333333;
            line-height: 50px;
          }
        }
        .el-dialog__body{
          padding: 30px;
          .closeIcon{
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
          }
          .closeIcon:hover{
            color: #673000;
          }
          .table_show{
            width: 100%;
            // overflow: auto;
            // max-height: 400px;
            color: #333333;
            text-align: left;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            #tfhover{
              width: 100%;
              tr{
                height: 40px;
                display: flex;
                line-height: 40px;
                th{
                  flex: 1;
                  background: #F7F7F7;
                  padding:0 10px;
                  color: #999999;
                  border: 1px solid #EAEAEA;
                }
                td{
                  padding:0 10px;
                  flex: 1;
                  margin-top: -1px;
                  border: 1px solid #EAEAEA;
                  .btnbuy{
                    color: #C6824E;
                    cursor: pointer;
                  }
                  .saleitem{
                    font-size: 10px;
                    color: #C6824E;
                  }
                }
              }
              tr:hover{
                td{
                  transition: all 0.2s;
                  background: #F7F7F7;
                }
              }
              .nodata{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                  margin: 0 auto;
                  height: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
