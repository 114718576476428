<template>
  <div id="list">
    <div id="titbutton">
      <el-button style="color: #FFFFFF;"
      @click="toBack">{{$t('m.map.Quick_trade')}} <el-icon><refresh /></el-icon></el-button>
    </div>
    <div id="table_show"
    v-if="update">
      <Table :datalist="state.dataList"/>
      <YushouTable/>

    </div>
    <div id="table_footer">
      <!-- <div id="select_se">
        <el-select v-model="value"
        class="showse" placeholder=""
        clearable filterable
        @change="selectValue">
          <el-option
          v-for="item in options"
          :key="item.id"
          :value="item"
          :label="item.name"
          >
          </el-option>
        </el-select>
      </div> -->
      <div id="foot_button">
        <button id="buy" @click="BuyBox()">{{$t('m.map.buy')}}</button>
        <button id="sale" @click="SaleBox()">{{$t('m.map.sale')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRaw } from 'vue'
import Table from '../../components/Table'
import emitter from '../../tools/bus'
import http from '../../api/http.js'
import YushouTable from './YushouTable.vue'
import { getStore } from '../../tools/storage'

const resaledetail = (data) => { // 查询转售详情
  return http.get('/mall-order/resale/detail', data)
}
export default {
  name: 'DataMap',
  components: { Table, YushouTable },
  data () {
    return {
      value: ref(''), // 选择的品种名称数据
      state: reactive({
        dataList: {
          // title: ['名称', '价格', '涨幅'],
          title: [this.$t('m.map.name'), this.$t('m.map.price'), this.$t('m.map.amount_of_increase')],
          cont: [],
          select: ''
        }
      }),
      coffeeClick: ref(''),
      selectValueData: reactive({}),
      options: ref([]),
      update: true
    }
  },
  methods: {
    // 存储选择框选择的值
    selectValue (e) {
      // this.selectValueData = e
      // console.log(e)
      this.selectValueData = {
        id: e.id,
        symbol: undefined,
        productName: e.name
      }
      this.value = e.name
      // console.log(this.value)
      // console.log(this.selectValueData)
    },
    // 更新选择框方法
    updateSelect (cityData) {
      this.options = ref([])
      // console.log(cityData)
      // const arr = []
      for (let i = 0; i < cityData.length; i++) {
        this.options.push({
          id: cityData[i].id,
          variety: cityData[i].variety,
          label: cityData[i].variety
        })
      }
    },
    // 默认初始 发送请求渲染页面数据
    useGetListEffect () {
      const getdata = {
        // pageNum: 1,
        // pageSize: 20
        rankingClassification: 3
      }
      // 列表数据查询接口
      http.get('/mall-portal/home/getSuccessOrderList', getdata, true)
        .then(res => {
          // console.log(res)
          // this.state.dataList.title = ['名称', '价格', '涨幅']
          this.state.dataList.title = [this.$t('m.map.name'), this.$t('m.map.price'), this.$t('m.map.amount_of_increase')]
          this.state.dataList.cont = res.data.data
          this.state.dataList.select = ''
        }).catch(err => {
          console.log(err)
        })
      // 请求选择框数据
      http.get('/mall-portal/home/topicList')
        .then(res => {
          console.log(res)
          this.options = res.data.data
          // console.log(this.options)
        }).catch(err => {
          console.log(err)
        })
    },
    // 点买入 渲染页面
    BuyBox () {
      // this.store.state.cityId
      if (this.$store.state.citysId) {
        console.log(this.$store.state.citysId)
        this.selectValueData = {
          id: null,
          symbol: this.$store.state.citysId,
          productName: ''
        }
        this.$store.state.citysId = ''
        const dataList = [{ select: toRaw(this.selectValueData) }, []]
        this.$emit('toBoxs', { goto: 'MessageTableBuy', datalist: dataList })
      }
      // 点击去到买入界面，需要传入选择框的数据，从而发送给接口
      // console.log(selectValueData)
      // 存选择框的数据
      const dataList = [{ select: toRaw(this.selectValueData) }, []]
      // 传入当前data表的数据
      // const dataName = this.state.dataList.cont
      // console.log(dataName)
      // for (let i = 0; i < dataName.length; i++) {
      //   // console.log(dataName[i])
      //   dataList[1].push({
      //     id: dataName[i].id,
      //     // price: dataName[i].price,
      //     // unit: '元/吨', // 后期可能需要添加字段
      //     // // wareId: dataName[i].wareId,
      //     // wareName: dataName[i].wareName,
      //     variety: dataName[i].variety
      //   })
      // }
      // console.log(dataList)
      //  ！！！！！！！！！！！！！！！！！！！！ 传值给父组件Map
      this.$emit('toBoxs', { goto: 'MessageTableBuy', datalist: dataList })
    },
    // 点卖出 渲染页面
    SaleBox () {
      const dataList = [{ select: this.selectValueData }]
      // 传入当前data表的数据
      this.$emit('toBoxs', { goto: 'MessageTableSale', datalist: dataList })
    }
  },
  mounted () {
    this.useGetListEffect()
    // 获取点击的商品信息
    emitter.on('coffeeClick', (data) => {
      console.log(typeof data)
      // this.state.dataList.title[0] = data.variety
      // this.value = data.variety
      if (typeof data === 'object') {
        return
      }
      const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      // console.log(userId)
      const getdata = {
        id: data.id,
        userId: userId ? userId.id : null
      }
      resaledetail(getdata) // 查出商品的转售信息
        .then(res => {
          console.log(data.id)
          console.log(res)
          if (res.data.code === 200) {
            const item = res.data.data
            // console.log(item)
            emitter.emit('toBoxs', { goto: 'MessageBoxQuickBuy', datalist: item })
          } else if (res.data.msg === '该商品不存在') {
            return ''
          }
        }).catch(err => {
          console.log(err)
        })
      // console.log(res)

      // console.log(this.state)
    })
    emitter.on('cityClick', (cityData) => {
      // this.state.dataList.title = ['名称', '价格', '数量']
      this.state.dataList.title = [this.$t('m.map.name'), this.$t('m.map.price'), this.$t('m.map.quantity')]
      this.state.dataList.cont = cityData[0]
      // 当前城市点击传来的数据 是当前城市
      this.state.dataList.select = cityData[1]
      // console.log(cityData)
      // 收到地图传来的信息城市的数据 赋值给渲染数据
      this.update = false
      this.update = true
    })
  },
  setup (props, ctx) {
    // const { ctx } = getCurrentInstance()
    // const bianclass = (sss) => {
    //   // ctx.emit('bianclass', sss)
    // }
    const toBack = () => {
      window.location.reload()
    }
    return {
      toBack
      // selectValueData,
      // options,
      // state,
      // bianclass
      // BuyBox,
      // SaleBox
      // useGetListEffect,
      // selectValue
    }
  }
}
</script>

<style lang="scss">
#list{
  height: 100%;
  background: #122149;
  // padding: 20px;
  box-sizing: border-box;
  position: relative;
  #titbutton{
    padding: 20px;
    .el-button{
      width: 100%;
      border-color: #AC703B;
      background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
    }
  }
  #table_footer{
    width: 100%;
    padding: 20px;
    // margin-top: 10px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    #select_se{
      width: 100%;
      .el-select{
        display: block;
        .select-trigger{
          .el-input--suffix{
            .el-input__inner{
              color: #DCDCDC;
              background: rgba(242, 242, 242, 0.2);
              border: 1px solid #DCDCDC;
            }
          }
        }
      }
    }
    #foot_button{
      width: 100%;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      button{
        color: #FFFFFF;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        width: 110px;
        height: 40px;
        border: 1px solid #AC703B;
        border-radius: 4px;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
      }
    }
  }
}

// 选择框文字颜色
.el-select-dropdown__item{
  span{
    font-size: 14px;
    font-family: Microsoft YaHei;
    color: #666666;
  }
}
</style>
