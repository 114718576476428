export function BMPGL (ak) {
  return new Promise((resolve, reject) => {
    if (typeof BMapGL !== 'undefined') {
      // eslint-disable-next-line no-undef
      resolve(BMapGL)
      return true
    }
    window.init = function () {
      // eslint-disable-next-line
      resolve(BMapGL)
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://api.map.baidu.com/api?v=3.0&type=webgl&ak=${ak}&callback=init`
    script.onerror = reject
    document.head.appendChild(script)
  })
}
