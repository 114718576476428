<template>
  <div id="chart_page">
    <div id="chart_show">
      <div id="show"></div>
    </div>
    <div id="area">
      <div id="area_head">
        <span>{{areaList.name}}</span>
      </div>
      <div id="area_body">
        <el-descriptions >
          <el-descriptions-item label="地址：">
            <!-- {{areaList.detailedAddress}} -->
            {{areaList.name}}
            </el-descriptions-item>
          <!-- <el-descriptions-item label="供应商：">
            {{areaList.supplier}}
            </el-descriptions-item> -->
          <el-descriptions-item label="最高价：">
            ￥{{maxPrice}}{{areaList.unit}}
            </el-descriptions-item>
          <el-descriptions-item label="库存量：">
            {{allData.stockSum}}{{areaList.unit}}
            </el-descriptions-item>
          <!-- <el-descriptions-item label="在线转售：">
            {{areaList.count}}{{areaList.unit}}
            </el-descriptions-item -->
          >
        </el-descriptions>
      </div>
      <div id="area_foot">
        <button id="fastbuy" @click="fastBuyBox()">快捷买入</button>
        <button id="fastsale" @click="fastSaleBox()">快捷卖出</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
// import { ref } from 'vue'

// import http from '../../api/http.js'
import emitter from '../../tools/bus'
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import http from '../../api/http'

export default {
  name: 'ChartShow',
  data () {
    return {
      chartsSetOption: reactive({
        // title: {
        //   text: '行情展示'
        // },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            // smooth: true,
            type: 'line',
            stack: 'Total',
            symbol: 'circle', // 改为实心点
            data: [1500, 3200, 3950, 4100, 5200, 5290, 9520],
            itemStyle: {
              color: '#c5854d', // 折线点的颜色
              lineStyle: {
                color: '#d3bea5'// 折线的颜色
              }
            }
          }
        ]
      }),
      areaList: reactive({}), // 站点信息
      maxPrice: ref(null), // 最高价
      allData: '', // 所有信息
      payAmount: ref(0),
      varietyList: reactive('') // 品种信息
    }
  },
  components: {
  },
  computed: {},
  mounted () {
    emitter.on('tableClick', (data) => {
      // console.log(data)
      this.chartAndShow(data)
    })
  },
  methods: {
    // echarts表格实现方法
    getOptions () {
      const mychart = echarts.init(document.getElementById('show'))
      mychart.setOption(this.chartsSetOption)
    },
    chartAndShow (data) {
      console.log(data)
      if (data.coffee === '' && data.wareName === '') {
        console.log(data)
        ElMessage({
          showClose: true,
          message: '数据可能有变化，请按Ctrl+F5刷新！',
          type: 'warning'
        })
      } else {
        console.log('数据展示！')
        // this.areaList = data
        this.varietyList = data
        const getData = {
          siteId: data.coffee,
          subjectId: Number(data.wareid)
        }
        console.log(getData)
        console.log(this.varietyList)
        http.get('/mall-portal/home/getSiteInfoBySiteIdAndSubjectId', getData)
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
              this.allData = res.data.data
              this.areaList = res.data.data.pmsSiteEntity
              this.maxPrice = res.data.data.maxPrice
              this.chartsSetOption.series[0].data = res.data.data.priceList
              console.log(this.chartsSetOption)
              this.getOptions()
              // emitter.emit('dataMap', { isShow: true })
            }
          }).catch(err => {
            console.log(err)
          })

        // charts图表展示信息
      }
    },
    // 点击快捷买入
    fastBuyBox () {
      console.log(this.areaList)
      console.log(this.varietyList)
      if (this.areaList.name && this.varietyList.productId | this.varietyList.wareid) {
        emitter.emit('toBoxs', {
          goto: 'MessageTableBuy',
          datalist: [{
            select: {
              symbol: Number(this.varietyList.coffee),
              id: Number(this.varietyList.wareid),
              productName: '查专题'
            }
          }]
        })
        // toQuickBuyData.allSkuName = []
      } else {
        ElMessage.warning({
          message: '请选择仓库后买入'
        })
        return ''
      }
    },
    // 点击快捷卖出
    fastSaleBox () {
      console.log('快捷卖出')

      // this.$emit('toBoxs', { goto: 'MessageBoxQuickSale', datalist: [cityName, this.areaList] })
      // 到个人页面卖出
      this.$emit('toBoxs', { goto: 'MessageTableSale' })
    }
  },
  setup (props, ctx) {
    return {
    }
  }
}
</script>

<style lang="scss" scroped>

#chart_page{
  width: 100%;
  height: 100%;
  background: rgba(62, 39, 27, 0.9);
  display: flex;
  #chart_show{
    flex: 1;
    float: left;
    width: 600px;
    transition: all, 1s;
    #show{
      width: 600px;
      height: 353px;
    }
  }
  #area{
    padding: 16px 20px 20px 20px;
    float: left;
    width: 280px;
    // background: rgba(62, 39, 27, 0.9);
    display: flex;
    flex-flow: column;
    #area_head{
      height: 18px;
      display: flex;
      justify-content: space-between;
      span{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #AC703B;
      }
    }
    #area_body{
      flex: 1;
      margin: 16px 0;
      padding: 20px 0;
      border-top: 1px solid #3E271B;
      border-bottom: 1px solid #3E271B;
      .el-descriptions{
        .el-descriptions__body{
          background: transparent;
          tr{
            .el-descriptions__cell{
              display: flex;
              font-size: 14px;
              padding-bottom: 12px;
              justify-content: center;
              .el-descriptions__label{
                text-justify:distribute-all-lines;/*ie6-8*/
                text-align-last:justify;/* ie9*/
                -moz-text-align-last:justify;/*ff*/
                -webkit-text-align-last:justify;/*chrome 20+*/
                width: 70px;
                color: #999999;
                margin: 0;
              }
              .el-descriptions__content{
                flex: 1;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
    #area_foot{
      width: 100%;
      display: flex;
      justify-content: space-between;
      button{
        color: #FFFFFF;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        width: 100px;
        height: 40px;
        border-radius: 4px;
      }
      #fastbuy{
        background: #FF0000;
      }
      #fastsale{
        background: #00C200;
      }
    }
  }
}
</style>
