<template>
<div class="buydlog">
  <el-dialog
    v-model="centerDialogVisible"
    :title="$t('m.map.buy')"
    width="700px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    center
  >
    <el-icon class="closeIcon" @click="remove()"><Close /></el-icon>

    <el-radio-group v-model="listNow" size="small"
      @change="changListNow">
      <el-radio-button label="product">{{$t('m.map.presell')}}</el-radio-button>
      <el-radio-button label="resaleProduct">{{$t('m.map.resell')}}</el-radio-button>
    </el-radio-group>
    <el-scrollbar class="table_body" max-height="400px">
      <div class="table_show"
          v-loading="tableloading">
        <table id="tfhover" class="tftable">
          <tr>
            <th v-for="i in dataTitle" :key="i">
              {{i}}
            </th>
          </tr>
          <tr class="heng" v-for="(item, index) in listData" :key="index">
            <td>{{listNow=='resaleProduct'?item.productName:item.name}}</td>
            <td>{{
              listNow=='resaleProduct'
              ?item.companyName:
              item.umsCompanyInfo==null
              ?'':
              item.umsCompanyInfo.companyName}}</td>
            <td>{{
              listNow=='resaleProduct'
              ?item.siteName:
              item.pmsSiteEntity==null
              ?'':
              item.pmsSiteEntity.name}}</td>
            <td>{{item.currencySymbol+(listNow=='resaleProduct'?item.resalePrice:item.price)+'/'+item.unit}}</td>
            <td v-if="listNow=='resaleProduct'">{{item.stock+item.unit}}</td>
            <td>
              <span class="btnbuy" @click="submitdata(item,listNow)">{{$t('m.map.buy')}}</span>
            </td>
          </tr>
          <div class="nodata" v-if="listData.length == 0">
            <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
          </div>
        </table>
      </div>
    </el-scrollbar>
    <div class="moredata" v-if="listData.length != 0">
      <el-button size="mini"
       @click="initdata">
        {{$t('m.map.load_more')}}
      </el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'

import { Close } from '@element-plus/icons'
// import tomessage from '../tools/tomessage.js'
import http from '../api/http.js'
import { ElMessage } from 'element-plus'
import ck from '../tools/cookie.js'
import emitter from '../tools/bus.js'
import { getStore } from '../tools/storage.js'

const resaledetail = (data) => { // 查询转售详情
  return http.get('/mall-order/resale/detail', data)
}
// 查快捷买入数据
const getQuickBuyList = (data) => {
  return http.get('/mall-portal/home/getQuickBuyList', data)
}
export default {
  name: 'MessageTableBuy',
  data () {
    return {
      // dataTitle: reactive(['品种', '商家', '仓库', '价格', '在转数量', '操作']),
      dataTitle: reactive([
        this.$t('m.map.variety'),
        this.$t('m.map.merchant'),
        this.$t('m.map.warehouse'),
        this.$t('m.map.price'),
        this.$t('m.map.Quantity_in_transit'),
        this.$t('m.map.operation')
      ]),
      allData: reactive([]), // 初始所有数据
      listData: reactive([]), // 当前展示数据
      listNow: ref('product'), // 当前选择的选项

      form: reactive({
        // 所有的品种
        breedname: [],
        // 当前选择的品种名  初始赋值
        breedregion: this.$props.boxdata.datalist[0].select.variety,
        // 所有的仓库
        statename: [],
        // 当前选择的仓库名
        stateregion: '',
        breedprice: {
          price: this.$props.boxdata.datalist[0].select.price,
          unit: this.$props.boxdata.datalist[0].select.unit
        },
        breedmount: {
          mount: 1,
          max: 2000,
          min: 1
        },
        loading: false
      }),

      tableloading: true, // 列表加载
      pageIndexNum: 1,
      nodata: false,
      resellNodata: false
    }
  },
  components: {
    Close
  },
  methods: {
    changListNow (e) {
      // console.log(e)
      console.log(this.allData)
      this.listNow = e
      if (e === 'resaleProduct') {
        // this.dataTitle = ['品种', '商家', '仓库', '价格', '在转数量', '操作']
        this.dataTitle = [
          this.$t('m.map.variety'),
          this.$t('m.map.merchant'),
          this.$t('m.map.warehouse'),
          this.$t('m.map.price'),
          this.$t('m.map.Quantity_in_transit'),
          this.$t('m.map.operation')
        ]
        this.listData = this.allData.resaleProduct.records
        console.log(this.listData)
      } else {
        this.listData = this.allData.product.records
        // this.dataTitle = ['品种', '商家', '仓库', '价格', '操作']
        this.dataTitle = [
          this.$t('m.map.variety'),
          this.$t('m.map.merchant'),
          this.$t('m.map.warehouse'),
          this.$t('m.map.price'),
          this.$t('m.map.operation')
        ]
        console.log(this.listData)
      }
    },
    // 根据table data传来的参数，发送请求  给listdata赋值
    showTableData () {
      console.log(this.$props.boxdata.datalist)
      const data = this.$props.boxdata.datalist[0].select
      if (data.symbol === undefined) {
        const dataToName = {
          pageNum: this.pageIndexNum,
          pageSize: 20
        }
        // const loading = this.$loading({
        //   lock: true,
        //   text: '加载中'
        // })
        this.tableloading = true
        // console.log(dataToName)
        http.get('/mall-portal/home/getQuickBuyList', dataToName)
          .then((res) => {
            console.log(res)
            this.allData = res.data.data // 转售数据
            this.changListNow(this.listNow)
            // loading.close()
            this.tableloading = false
          }).catch((err) => {
            console.log(err)
          })
      } else {
        let dataToName = {
          pageNum: 1,
          pageSize: 20
        }
        this.tableloading = true
        if (data.productName.charAt(data.productName.length - 1) === '市') {
          console.log('城市')
        } else {
          dataToName = {
            pageNum: 1,
            pageSize: 20,
            subjectId: data.id, // 品种（专题）
            siteId: data.symbol
          }
          http.get('/mall-portal/home/getQuickBuyList', dataToName)
            .then((res) => {
              // console.log(res)
              this.allData = res.data.data // 转售数据
              this.changListNow(this.listNow)
              this.tableloading = false
              // console.log(this.$data.listData)
            }).catch((err) => {
              console.log(err)
            })
        }
      }
    },

    saveBreed_name () {
      // 获取所有信息的数据存到 品种名称里
      for (let i = 0; i < this.$props.boxdata.datalist[1].length; i++) {
        this.form.breedname.push(this.$props.boxdata.datalist[1][i])
      }
    },
    saveState_name (data) {
      // console.log(data)
      // 把当前品种所有城市存到stateName中
      this.form.statename = data
    },
    // 查快捷买入数据
    async initdata () {
      this.pageIndexNum++
      this.tableloading = true

      let data = {
        pageNum: this.pageIndexNum,
        pageSize: 20
      }
      const dataSelect = this.$props.boxdata.datalist[0].select
      if (dataSelect.symbol !== undefined) {
        data = {
          pageNum: this.pageIndexNum,
          pageSize: 20,
          subjectId: dataSelect.id, // 品种（专题）
          siteId: dataSelect.symbol
        }
        this.initCityData(data)
        return
      }
      if (!this.nodata && !this.resellNodata) {
        const res = await getQuickBuyList(data)
        if (res.data.code === 200) {
          console.log(res.data.data)
          for (const item in res.data.data) {
            console.log(item)
            // console.log(...res.data.data[item].records)
            if (item === 'product' && res.data.data[item].records.length <= 0) {
              this.nodata = true
              // continue
            } else if (item === 'resaleProduct' && res.data.data[item].records.length <= 0) {
              this.resellNodata = true
            }
            this.allData[item].records.push(...res.data.data[item].records)
          }
          this.tableloading = false
          console.log(this.allData)
        }
      } else {
        ElMessage.warning({
          message: '没有更多数据了'
        })
        this.tableloading = false
      }
    },
    // 根据城市查数据
    async initCityData (data) {
      if (!this.nodata && !this.resellNodata) {
        const res = await getQuickBuyList(data)
        if (res.data.code === 200) {
          console.log(res.data.data)
          for (const item in res.data.data) {
            // console.log(...res.data.data[item].records)
            if (item === 'product' && res.data.data[item].records.length <= 0) {
              this.nodata = true
              // continue
            } else if (item === 'resaleProduct' && res.data.data[item].records.length <= 0) {
              this.resellNodata = true
            }
            this.allData[item].records.push(...res.data.data[item].records)
          }
          this.tableloading = false
          console.log(this.allData)
        }
      } else {
        ElMessage.warning({
          message: '没有更多数据了'
        })
        this.tableloading = false
      }
    }
  },
  mounted () {
    this.showTableData()
    // this.saveBreed_name()
    // console.log(this.$data.listData)
    // this.selectBreedValue(this.$props.boxdata.datalist[0].select)
  },
  props: ['boxdata'],
  setup (props, ctx) {
    const router = useRouter()
    // console.log(props.boxdata)

    // 窗口默认参数===不能更改删除
    var centerDialogVisible = ref(true)
    // const datacop = getCurrentInstance()

    // 关闭弹窗操作
    const remove = () => {
      ctx.emit('removethis', false)
    }
    // 买入
    const submitdata = async (item, name) => {
      console.log(item)
      console.log(name)
      if (ck.getCookie('token') === '') {
        ElMessage({ message: '请先登录!', type: 'warning', duration: 1500 })
        router.push('/loginandregister')
      } else {
        if (name === 'resaleProduct') { // 转售订单
          // console.log(item)
          const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
          // console.log(userId)
          const data = {
            id: item.id,
            userId: userId ? userId.id : null
          }
          const res = await resaledetail(data)
          // console.log(res)
          if (res.data.code === 200) {
            item = res.data.data
            emitter.emit('toBoxs', { goto: 'MessageBoxQuickBuy', datalist: item })
          } else {
            ElMessage.warning({
              message: res.data.msg
            })
          }
          // http.post('/mall-order/order/quickBuyGenerateOrder', postdata, true)
        } else { // 预售订单
          emitter.emit('toBoxs', { goto: 'MessageBoxQuickBuy', datalist: item })
        }
      }
      // centerDialogVisible = false
    }

    return {
      // firstData,
      centerDialogVisible,
      remove,
      submitdata
    }
  }
}
</script>
<style lang="scss">
.buydlog{
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        width: 828px;
        .el-dialog__header{
          height: 50px;
          padding: 0 20px;
          border-bottom: 1px solid #EAEAEA;
          .el-dialog__title{
            width: 100%;
            color: #333333;
            line-height: 50px;
          }
        }
        .el-dialog__body{
          padding: 30px;
          .closeIcon{
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
          }
          .closeIcon:hover{
            color: #673000;
          }
          .table_body{
            // min-height: 400px;
          }
          .table_show{
            width: 100%;
            // overflow: auto;
            min-height: 300px;
            color: #333333;
            text-align: left;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            #tfhover{
              width: 100%;
              tr{
                height: 40px;
                display: flex;
                line-height: 40px;
                th{
                  flex: 1;
                  background: #F7F7F7;
                  padding:0 10px;
                  color: #999999;
                  border: 1px solid #EAEAEA;
                }
                td{
                  padding:0 10px;
                  flex: 1;
                  margin-top: -1px;
                  border: 1px solid #EAEAEA;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  .btnbuy{
                    color: #C6824E;
                    cursor: pointer;
                  }
                }
              }
              tr:hover{
                td{
                  transition: all 0.2s;
                  background: #F7F7F7;
                }
              }
              .nodata{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                  margin: 0 auto;
                  height: 300px;
                }
              }
            }
          }
          .moredata{
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
