// import { ElMessage } from 'element-plus'
// import vue from 'vue'
// import IDLogin from '../views/loginandregister/login/IDLogin'
// import PhoneLogin from '../views/loginandregister/login/PhoneLogin'
// import Register from '../views/loginandregister/register/Register'
// import ResetPwd from '../views/loginandregister/ResetPwd'
// import RetrievePwd from '../views/loginandregister/RetrievePwd'

// const components = [
//   IDLogin, PhoneLogin, Register, ResetPwd, RetrievePwd
// ]
var email = /^(\w+\.?)*\w+@(?:\w+\.)\w+$/ // 邮箱
var tel = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/ // 手机号码
var password = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/ // 密码
var saleprc = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/ // 价格 两位小数

// const str = ''

const fromvalidate = function (rule, value) {
  var data = {
    duration: 1500,
    showClose: true,
    message: '',
    type: ''
  }

  switch (rule) {
    case 'Phone':
      Object.assign(data, Phone(value, data))
      break
    case 'User':
      Object.assign(data, User(value, data))
      break
    case 'Password':
      Object.assign(data, Password(value, data))
      break
    case 'Confirmpwd':
      Object.assign(data, Confirmpwd(value, data))
      break
    case 'AuthCode':
      Object.assign(data, AuthCode(value, data))
      break
    case 'quickBuy':
      Object.assign(data, quickBuy(value, data))
      break
    case 'quickSale':
      Object.assign(data, quickSale(value, data))
      break
    case 'order':
      Object.assign(data, order(value, data))
      break
    default:
      break
  }
  // 验证码
  function AuthCode (value, pdata) {
    if (!value) {
      pdata.message = '验证码不能为空'
      pdata.type = 'error'
      return pdata
    } else {
      pdata.type = 'success'
      return pdata
    }
  }
  // 确认密码密码
  function Confirmpwd (value, pdata) {
    if (!value.olddata) {
      pdata.message = '密码不能为空'
      pdata.type = 'error'
      return pdata
    }
    if (value.data !== value.olddata) {
      // console.log(value)
      pdata.message = '两次密码不一致！'
      pdata.type = 'error'
      return pdata
    } else {
      pdata.message = '可以注册！'
      pdata.type = 'success'
      return pdata
    }
  }
  // 密码的正则
  function Password (value, pdata) {
    if (!value) {
      pdata.message = '密码不能为空'
      pdata.type = 'error'
      return pdata
    }
    if (!password.test(value)) {
      pdata.message = '请正确填写密码（含有数字和字母，且长度要在8-16位之间）'
      pdata.type = 'error'
      return pdata
    } else {
      pdata.message = '密码符合格式！'
      pdata.type = 'success'
      return pdata
    }
  }
  // 邮箱（用户名）的正则
  function User (value, pdata) {
    if (!value) {
      pdata.message = '用户名（邮箱）不能为空'
      pdata.type = 'error'
      return pdata
    }
    if (!email.test(value)) {
      pdata.message = '请正确填写用户名（邮箱）'
      pdata.type = 'error'
      return pdata
    } else {
      pdata.message = '用户名正确！'
      pdata.type = 'success'
      return ''
    }
  }
  // 电话号码的验证
  function Phone (value, pdata) {
    if (!value) {
      pdata.message = '手机号码不能为空'
      pdata.type = 'error'
      return pdata
    }
    if (!tel.test(value)) {
      pdata.message = '请正确填写电话号码'
      pdata.type = 'error'
      return pdata
    } else {
      pdata.message = '手机号正确！'
      pdata.type = 'success'
      return pdata
    }
  }
  // 快捷买入消息
  function quickBuy (value, bdata) {
    if (!value.breedregion) {
      bdata.message = '请选择买入品种'
      bdata.type = 'error'
      return bdata
    } else if (!value.stateregion) {
      bdata.message = '请选择仓库'
      bdata.type = 'error'
      return bdata
    } else if (!value.withholdingTime) {
      bdata.message = '请选择预提时间'
      bdata.type = 'error'
      return bdata
    } else {
      bdata.message = '等待生成订单！'
      bdata.type = 'warning'
      return bdata
    }
  }
  // 快捷卖出消息
  function quickSale (value, bdata) {
    if (!value.salePrice) {
      bdata.message = '请输入出售价格'
      bdata.type = 'error'
      return bdata
    } else if (!saleprc.test(value.salePrice)) {
      bdata.message = '请输入正确的价格'
      bdata.type = 'error'
      return bdata
    } else if (!value.toSellDay) {
      bdata.message = '请选择到期日'
      bdata.type = 'error'
      return bdata
    } else {
      bdata.message = '正在处理!'
      bdata.type = 'warning'
      return bdata
    }
  }
  // 订单页面
  function order (value, ddata) {
    if (value.time1.length === 0) {
      ddata.message = '没有选择预提日期！'
      ddata.type = 'error'
      return ddata
    } else {
      ddata.message = '订单已确认，请等候处理'
      ddata.type = 'warning'
      return ddata
    }
  }
  return {
    data
  }
}

export default {
  fromvalidate
  // ...components
}
