<template>
    <div id="map">
    </div>
</template>

<script>
// import { reactive, onMounted } from 'vue'
// import showMap from './showMap.js'
// import stote from 'vuex'
import { reactive, ref } from 'vue-demi'
// import { getCurrentInstance } from 'vue'

import emitter from '../../tools/bus'
import { BMPGL } from './bmpgl.js'
import http from '../../api/http'
import { useStore } from 'vuex'
// import { ElMessage } from 'element-plus'

export default {
  name: 'BaiduMap',
  // props: ['ak'],
  data () {
    return {
      point: '',
      lat: '',
      mymap: null
    }
  },
  methods: {
  },
  // created () {
  //   // 默认初始 发送请求渲染页面 城市数据
  //   // 请求数据
  //   // emitter.on('citysNameinit', (data) => {
  //   //   // console.log(data)
  //   //   this.mapdata.citys = data
  //   //   console.log(this.mapdata.citys)
  //   // })
  // },
  mounted () {

  },
  setup (props, ctx) {
    const store = useStore()
    // const docop = getCurrentInstance()
    // console.log(docop)
    const mapdata = reactive({
      ak: 'SczRL51fV1aDx6ZMmUAuuHCqYkQU6je0',
      citys: []
    })
    var num = ref(0)

    const useGetListEffect = () => { // 初始化城市数据
      http.get('/mall-portal/pmsSite/selectList')
        .then(res => {
          // console.log(res)
          var arr = []
          for (let i = 0; i < res.data.data.length; i++) {
            arr.push({
              citys: res.data.data[i].name,
              img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/coffeeBean2.png',
              stock: res.data.data[i].count,
              id: res.data.data[i].id
            })
          }
          // mapdata.citys = newArr
          mapdata.citys = arr
          initMap()
          arr = null
        }).catch(err => {
          // console.log('12345')
          console.log(err)
        })
    }

    emitter.on('cityName', (cityMapData) => {
      console.log(cityMapData)
      mapdata.citys = cityMapData.citys
      initMap()
    })
    // // 默认初始 发送请求渲染页面 城市数据
    const initMap = () => {
      // 传入密钥获取地图回调。
      // const _this = this
      BMPGL(mapdata.ak).then((BMapGL) => {
        // 创建地图实例

        var map = new BMapGL.Map('map', {
          restrictCenter: false
        })
        var point = new BMapGL.Point(112.938, 37.415)
        map.centerAndZoom(point, 5)
        map.enableScrollWheelZoom()
        var scaleCtrl = new BMapGL.ScaleControl()
        map.addControl(scaleCtrl)
        // 添加缩放控件
        map.addControl(new BMapGL.ZoomControl({
          // anchor: 'BMAP_ANCHOR_TOP_LEFT',
          // offset: new BMapGL.Size(880, 20) // 进一步控制缩放按钮的水平竖直偏移量
        }))

        map.addControl(new BMapGL.NavigationControl3D(
          {
            anchor: 'BMAP_ANCHOR_BOTTOM_LEFT', // 左下角
            offset: new BMapGL.Size(1, 1) // 进一步控制缩放按钮的水平竖直偏移量
          }
        ))
        // 样式的ID
        // map.setMapStyleV2({
        //   styleId: '9c2352b9c5294199e3177ffce4767ae8'
        // })
        map.setMapStyleV2({ // blue
          styleId: '97181c71f07fd94879d6c9703c3c3324'
        })
        // 存储需要标注的城市信息

        // 清除地图上的覆盖物
        // map.clearOverlays()
        var count = mapdata.citys.length
        // console.log(count)
        if (count === 0) {
          // this.loading = true
          // return this.initMap()
          return null
        }
        // console.log(rs)
        // var pointArray = []
        // for (let i = 0; i < count; i++) {
        //   // boundaries 是 rs 对象的参数名
        //   // 此处把所有的参数信息用  #90c8f1色  画出来  凸显作用
        //   var ply = new BMapGL.Polygon(rs.boundaries[i], {
        //     strokeWeight: 1, strokeColor: '#90c8f1'
        //   }) // 建立多边形覆盖物
        //   // 添加到地图上
        //   // map.addOverlay(ply)
        //   // console.log(ply.getPath())
        //   // 返回多边形的点数组（坐标点）
        //   pointArray = pointArray.concat(ply.getPath())
        // }
        // console.log(pointArray)
        // map.setViewport(pointArray) // 调整视野 包含所有的边的坐标点

        // 绘制带高度的点
        // 创建一个地址解析器，传入地址，返回回调函数参数为地址的坐标点point
        var cityGeo = new BMapGL.Geocoder()
        for (let i = 0; i < mapdata.citys.length; i++) {
          cityGeo.getPoint(mapdata.citys[i].citys, function (point) {
            // pointArray = pointArray.push(point)
            // console.log(mapdata.citys[i].citys)
            var pt = new BMapGL.Point(point.lng, point.lat)
            // 创建贴图纹理Icon
            var icon = new BMapGL.Icon(mapdata.citys[i].img, new BMapGL.Size(100, 100))
            var marker = new BMapGL.Marker3D(pt, Math.round(Math.random()) * 13000, {
              size: 30,
              shape: 'BMAP_SHAPE_CIRCLE',
              fillColor: '#ac703b',
              fillOpacity: 0.2,
              duration: 1,
              icon: icon
            })
            marker.addEventListener('mouseover', function (e) {
              e.target.setFillColor('#f00')
              // console.log(e.target)
              // setShow(citys[i].citys, this.latLng)
              // console.log(e.latLng)
              citysStock(mapdata.citys[i])
              // 添加到地图中 标注信息
              setTimeout(() => {
                const opts = {
                  width: 0,
                  height: 0,
                  title: mapdata.citys[i].citys,
                  message: mapdata.citys[i].citys
                }
                const infoWindow = new BMapGL.InfoWindow(`库存量：<span style="font-size:20px;">
                ${num || (num === 0 ? 0 : '<img style="height:20px;" src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/lodding.gif"/>')}</span>`, opts)
                map.openInfoWindow(infoWindow, this.latLng)
              }, 100)
            })
            marker.addEventListener('mouseout', function (e) {
              e.target.setFillColor('#ac703b')
              // map.closeInfoWindow()
              num = null
            })
            marker.addEventListener('click', function (e) {
              // this指向当前点击的圆形图，有该地区的坐标
              // console.log(e.target)
              store.state.citysId = mapdata.citys[i].id
              console.log(store.state.citysId)
              setLatLng(mapdata.citys[i].id, mapdata.citys[i].citys)
            })
            map.addOverlay(marker)
            // return pointArray
          })
        }
        // var simpleLayer = new BMapGL.CircleLayer()
        // map.addLayer(simpleLayer)
        // 画面到哪个位置
        // map.setViewport(pointArray)
        // })
        // map.setViewport(pointArray)
        // ***************************************************************************************
      }).catch((err) => {
        console.log(err)
      })
      // 点击地区触发的请求事件
      function setLatLng (cityId, cityName) {
        console.log(cityId, cityName)
        http.get('/mall-portal/home/getResaleProductListBySiteId', {
          id: cityId
        })
          .then(res => {
            console.log(res)
            // 获取点击当前的地址信息 发送到dataMap中展示
            emitter.emit('cityClick', [res.data.data, { id: cityId, name: cityName }])
          }).catch(err => {
            console.log(err)
          })
      }
      // 移动到当前城市地区触发的事件// count库存
      function citysStock (cName) {
        // console.log(cName)
        num = cName.stock
      }
    }

    useGetListEffect()

    return {
      mapdata,
      useGetListEffect,
      initMap
    }
  }
}
</script>

<style lang="scss" scoped>
  #map{
    // border: 1px solid red;
    height: 100%;
    width: 100%;
    // z-index: 9999;
  }
</style>
