<template>
  <div v-if="isShow"
  id="yushou_table">
    <div id="card_head">
      <span>{{$t('m.map.Presale_information')}}</span>
      <!-- <el-icon @click="isShow=false"><Close /></el-icon> -->
    </div>
    <div id="card_body" @click="handle($event)">
      <div id="table_head">
        <tr v-for="(i,index) in dataList.title" :key="index">
          <th :class="index==0?'head1':''">{{i}}</th>
        </tr>
      </div>
      <vue-seamless-scroll  :data="dataList.cont"
          :class-option="optionHover"
          class="seamless-warp">
        <div id="table_body">
          <table>
            <tr
            v-for="(k,index1) in dataList.cont" :key="index1">
              <!-- <el-tooltip :content="k.name"
              placement="left" effect="light"> -->
              <td class="ef_name">{{k.name}}</td>
              <!-- </el-tooltip> -->
              <td>{{k.stock}}{{k.unit}}</td>
              <td>{{k.currencySymbol}}{{k.price}}/{{k.unit}}</td>
              <td class="buybuy" :data-code="k.productSn" @click="submitdata(k)">{{$t('m.map.buy')}}</td>
            </tr>
          </table>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
// import { reactive } from 'vue'
// import { useRouter } from 'vue-router'

import http from '../../api/http.js'

import emitter from '../../tools/bus.js'

import { ElLoading, ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage.js'
// import { Close } from '@element-plus/icons'

const detailBySnDetail = (data) => {
  return http.get('/mall-portal/product/detailBySn', data)
}

export default {
  name: 'YushouTable',
  data () {
    return {
      isShow: true,
      dataList: {
        // title: ['品种', '库存量', '价格', '操作'],
        title: [this.$t('m.map.variety'), this.$t('m.map.stock'), this.$t('m.map.price'), this.$t('m.map.operation')],
        cont: []
      }
    }
  },
  components: {
    // Close
  },
  computed: {
    optionHover () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  mounted () {
    this.initdata()
  },
  methods: {
    initdata () { // 初始化预售数据
      http.get('/mall-portal/home/getQuickBuyPresellList', {
        // // deliveryPeriod: this.paramsList.deliveryPeriod,
        // pageSize: 50,
        // pageNum: 1,
        // // previewStatus: 1
      })
        .then(res => {
          // console.log(res)
          this.dataList.cont = res.data.data
        }).catch(err => {
          console.log(err)
        })
    },
    close (event) {
      // event.currentTarget.className = 'classname'
    },
    async handle (e) {
      console.log(e)
      // for (let i = 0; i < e.path.length || 1; i++) {
      // console.log(e.target.dataset)
      if (e.target.className === 'buybuy') {
        console.log(e.target)
        // e.path[i].dataset.code
        console.log(e.target.dataset.code)
        // this.coffeeId = e.path[i].dataset.code || e.path[i].firstChild.innerHTML
        // console.log(coffeeId)
        if (e.target.dataset.code) {
          const token = getStore('token')
          const loading = ElLoading.service({
            lock: true,
            text: '请稍后...'
          })

          if (token === '') {
            ElMessage({ message: '请先登录!', type: 'warning', duration: 1500 })
            this.$router.push('/loginandregister')
          } else {
            const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
            // console.log(userId)
            const data = {
              productSn: e.target.dataset.code,
              userId: userId ? userId.id : null
            }
            const res = await detailBySnDetail(data)
            console.log(res)
            // const modeTime = (time) => {
            //   return time.substring(0, 10)
            // }

            // console.log(cityName)
            if (res.data.code === 200) {
              loading.close()
              emitter.emit('toBoxs', { goto: 'MessageBoxQuickBuy', datalist: { data: res.data.data, name: 'ys' } })
            }
            return ''
          }
        }
        // break
      }
      // }
    }
  },
  setup () {
    // const router = useRouter()
    const allbuy = async (e) => {
      console.log(e)
    }
    const submitdata = async (e) => { // 点击买入
      // console.log(e)
      // const loading = ElLoading.service({
      //   lock: true,
      //   text: '请稍后...'
      // })
      // const token = getStore('token')
      // if (token === '') {
      //   ElMessage({ message: '请先登录!', type: 'warning', duration: 1500 })
      //   router.push('/loginandregister')
      // } else {
      //   const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      //   // console.log(userId)
      //   const data = {
      //     productSn: e.productSn,
      //     userId: userId ? userId.id : null
      //   }
      //   const res = await detailBySnDetail(data)
      //   console.log(res)
      //   // const modeTime = (time) => {
      //   //   return time.substring(0, 10)
      //   // }

      //   // console.log(cityName)
      //   if (res.data.code === 200) {
      //     loading.close()
      //     emitter.emit('toBoxs', { goto: 'MessageBoxQuickBuy', datalist: { data: res.data.data, name: 'ys' } })
      //   }
      //   return ''
      // }
    }
    return {
      allbuy, submitdata
    }
  }
}
</script>

<style lang="scss" scoped>
#yushou_table{
  // width: 300px;
  // height: 360px;
  width: 100%;
  height: 240px;
  // background: #361302;
  opacity: 0.9;
  padding-top: 15px;
  box-sizing: border-box;
  #card_head{
    padding: 0 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    span{
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
    i{
      font-size: 18px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  #card_body{
    // height: 320px;
    // // overflow: hidden;
    #table_head{
      padding: 0 10px;
      display: flex;
      padding-top: 20px;
      tr{
        width: 100%;
        // text-align: center;
        th{
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .head1{
          min-width: 80px;
        }
      }
    }
    .seamless-warp{
      height: 150px;
      overflow: hidden;
      margin: 10px 0;
      #table_body{
        table{
          display: flex;
          flex-flow: column;
          tr{
            height: 30px;
            flex-shrink: 0;
            padding: 5px 10px;
            box-sizing: border-box;
            display: flex;
            td{
              flex: 1;
              font-size: 12px;
              font-family: Microsoft YaHei;
              line-height: 20px;
              font-weight: 400;
              color: #FFFFFF;
              width: 60px;
              white-space:nowrap; // 一行显示
              text-overflow: ellipsis; // 溢出变为省略号
              overflow: hidden;
            }
            .ef_name{
              min-width: 80px;
              padding-right: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            td:nth-child(4){
              color: #C6824E;
            }
          }
          tr:hover{
            cursor: pointer;
            background: rgba(255, 255, 255, 0.05);
          }
        }
      }
    }
  }
}
</style>
