import { createI18n } from 'vue-i18n'
import { getStore } from './storage'
const lang = getStore('exp2c_language') != null ? getStore('exp2c_language') : 'zh-CN' // 判断是否有缓存
const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages: {
    'zh-CN': require('../lang/zh'), // 通过require引入中文语言包
    'en-US': require('../lang/en') // 通过require引入英文语言包
  }
})
export default i18n
