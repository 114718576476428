<template>
<div class="quickbuydlog">
  <el-dialog
    v-model="centerDialogVisible"
    title="快捷买入"
    width="560px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    center
  >
    <!-- 转售的弹窗内容 -->
    <el-form ref="form" :model="form" label-width="120px" v-if="!this.$props.boxdata.datalist.name">
      <!-- 品种change改变时，触发查询仓库接口  返回该品种所有的仓库 -->
      <el-form-item label="品 种:" label-width="80px">
        <el-select
        size
         v-model="form.breedregion"
         @change="selectBreedValue"
         placeholder=" ">
          <el-option
            v-for="(i,k) in form.breedname" :key="k"
            :label="i.variety" :value="i">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="仓 库:" label-width="80px">
          <el-select
            size
            v-model="form.stateregion"
            placeholder=" "
            @change="selectStateValue">
            <el-option
              v-for="(i,k) in form.statename" :key="k"
              :label="this.form.statename[k].wareName" :value="i">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="预提时间:" label-width="80px">
        <span class="timerclass">{{form.withholdingTime}}</span>
        <!-- <div class="block">
          <el-date-picker
            v-model="form.withholdingTime"
            type="daterange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
            :disabledDate="pickerOptions.disabledDate"
          >
          </el-date-picker>
        </div> -->
      </el-form-item>
      <el-form-item label="规格:" label-width="80px">
        <span class="timerclass">{{form.guigeValue}}</span>
      </el-form-item>
      <el-form-item label="价 格:" label-width="80px">
        <span><i>{{this.form.breedprice.unit}}</i>{{this.form.breedprice.price}}</span>
      </el-form-item>
      <el-form-item label="数 量:" label-width="80px">
        <el-input-number
        v-model="form.breedmount.mount"
        size="small"
        :step="form.breedmount.step"
        :min="form.breedmount.min>form.breedmount.max?form.breedmount.max:form.breedmount.min"
        :max="form.breedmount.max"/>
        <p class="maxp">最大可买{{form.breedmount.max}}</p>
      </el-form-item>
      <el-form-item label="总金额:" label-width="80px">
        <span class="pay">{{form.breedprice.unit}}{{payMoney}}</span>
        <p class="maxp">预付比例{{thisData.product.prePayRate}}%</p>
      </el-form-item>
      <el-form-item label="应付定金:" label-width="80px">
        <span class="pay">{{form.breedprice.unit}}{{((payMoney / 100) * thisData.product.prePayRate).toFixed(2)}}</span>
      </el-form-item>
      <div class="tiaoyue">
        <el-checkbox  v-model="checkedTiaoYue" size="mini">
          我已阅读并同意
          <span class="tiaokuan" @click="readContract(2)">《购销合同》</span>、
          <span class="tiaokuan" @click="readContract(4)">《贸易规则》</span>、
          <span class="tiaokuan" @click="readContract(5)">《质量标准》</span>
        </el-checkbox>
      </div>
    </el-form>
    <!-- 预售弹窗内容 -->
    <el-form ref="form" :model="form" label-width="120px"
    v-if="this.$props.boxdata.datalist.name === 'ys' || this.$props.boxdata.datalist.isResale === 1">
      <!-- 品种change改变时，触发查询仓库接口  返回该品种所有的仓库 -->
      <el-form-item label="品 种:" label-width="80px">
        <span>{{form.breedregion}}</span>
      </el-form-item>
      <el-form-item label="仓 库:" label-width="80px">
          <span>{{form.stateregion}}</span>
      </el-form-item>

      <el-form-item label="价 格:" label-width="80px">
        <span><i>{{this.form.breedprice.unit}}</i>{{this.form.breedprice.price}}</span>
      </el-form-item>
      <el-form-item label="规 格:" label-width="80px">
        <el-select
        size
         v-model="form.guigeValue"
         @change="selectGuigeValue"
         value-key="id"
         placeholder=" ">
          <el-option
            v-for="i in form.guigeList" :key="i.id"
            :label="'规格:'+i.spData[1].value+` / 交货日:`+i.spData[0].value" :value="i">
          </el-option>
        </el-select>
        <!-- 级联选择 -->
        <!-- <el-cascader :options="options">
          <template #default="{ node, data }">
            <span>{{ data.label }}</span>
            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
          </template>
        </el-cascader> -->
      </el-form-item>
      <el-form-item label="交 货 日:" label-width="80px">
        <span>{{form.deliveryTime}}</span>
      </el-form-item>
      <el-form-item label="数 量:" label-width="80px">
        <el-input-number
        v-model="form.breedmount.mount"
        size="small"
        :step="form.breedmount.step"
        :min="form.breedmount.min>form.breedmount.max?form.breedmount.max:form.breedmount.min"
        :max="form.breedmount.max"/>
        <p class="maxp">最大可买{{form.breedmount.max+form.breedmount.unit}}</p>
      </el-form-item>
      <el-form-item label="总金额:" label-width="80px">
        <span class="pay">{{form.breedprice.unit}}{{payMoney}}</span>
        <p class="maxp">预付比例{{thisData.prePayRate}}%</p>
      </el-form-item>
      <el-form-item label="应付定金:" label-width="80px">
        <span class="pay">{{form.breedprice.unit}}{{((payMoney / 100) * thisData.prePayRate).toFixed(2)}}</span>
      </el-form-item>
      <div class="tiaoyue">
        <el-checkbox  v-model="checkedTiaoYue" size="mini">
          我已阅读并同意
          <span class="tiaokuan" @click="readContract(3)">《购销合同》</span>、
          <span class="tiaokuan" @click="readContract(4)">《贸易规则》</span>、
          <span class="tiaokuan" @click="readContract(5)">《质量标准》</span>
        </el-checkbox>
      </div>
    </el-form>
    <!-- <div>{{boxdata}}</div> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
        size="small"
        @click="centerDialogVisible = false,remove()"
        >取消</el-button>
        <el-button type="primary"
        size="small" :disabled="!checkedTiaoYue"
        :loading="form.loading"
        @click="submitdata(this.form)"
        >确定</el-button>
      </span>
    </template>
  </el-dialog>
</div>

<!-- 买入 支付弹窗 -->
    <el-dialog
      title="确认买入"
      v-model="showBuy"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
    >
      <div>
        <p style="line-height: 30px; margin-bottom:20px;">应付金额: <span>{{this.payAmount}}</span></p>
         <el-input
            placeholder="请输入6位支付密码"
            size="small"
            v-model="password"
            type="password"
            maxlength="6"
            minlength="6">
            </el-input>
        <div style="padding: 10px 0">
          <el-button size="small" style="width: 80px;"
            :disabled="advancedisabled"
            @click="subConfirmGoods"
            >提交</el-button>
        </div>
      </div>
    </el-dialog>
</template>

<script>
import { ref, getCurrentInstance, reactive, toRaw } from 'vue'
import http from '../api/http'
import { ElMessage } from 'element-plus'
import { getStore } from '../tools/storage'

// import ck from '../tools/cookie.js'

// import emitter from '../tools/bus.js'
// const datePresaleGenerateOrder = (data) => {
//   return http.post('/mall-order/order/datePresaleGenerateOrder', data, true)
// }
const getQuickBuySkuList = (id) => { // 查询可以选的规格
  return http.get(`/mall-portal/home/getQuickBuySkuList/${id}`)
}

export default {
  name: 'MessageBoxQuickBuy',
  data () {
    return {
      thisData: '',
      orderType: '', // 订单类型0：正常，1：秒杀，2：转售，3：预购
      form: reactive({
        // 所有的品种
        breedname: [],
        // 当前选择的品种名  初始赋值
        breedregion: '',
        // 所有的仓库
        statename: [],
        // 当前选择的仓库名
        stateregion: '',
        // 可预提时间范围
        canTime: {
          withholdingBeginTime: '',
          withholdingFinishTime: ''
        },
        // 预提时间
        withholdingTime: '',
        // 交货日
        deliveryTime: '',
        // 价格，单位
        breedprice: {
          price: 0,
          unit: ''
        },
        // 数量，最大数量
        breedmount: reactive({
          step: null,
          mount: 0,
          max: 1,
          min: 0,
          unit: ''
        }),
        // 规格
        guigeList: [],
        guigeValue: '',
        // 等待关闭 控制
        loading: false
      }),
      payMoney: ref(0),
      showBuy: ref(false), // 付款弹框
      checkedTiaoYue: ref(false),
      advancedisabled: ref(false), // 按钮禁用
      payAmount: '', // 应付金额
      password: '',
      orderSn: '', // 支付id

      options: ref([])
    }
  },
  watch: {
    'form.breedmount.mount' (newVal, oldVal) {
      this.payMoney = this.form.breedprice.price * newVal
    },
    'form.breedprice.price' (newVal, oldVal) {
      this.payMoney = newVal * this.form.breedmount.mount
    }
  },
  methods: {
    saveBreed_name () {
      // 获取所有信息的数据存到 品种名称里
      // for (let i = 0; i < this.$props.boxdata.datalist.length; i++) {
      //   this.form.breedname.push(this.$props.boxdata.datalist[1][i])
      // }
    },
    saveState_name (data) {
      // console.log(data)
      // 把当前品种所有城市存到stateName中
      this.form.statename = data
    },
    async selectBreedValue (e) {
      // 初始选择
      // e = toRaw(e)
      console.log(e)
      if (typeof e === 'object') {
        if (e.name === 'ys' || e.isResale === 1) { // 预售快捷买入的
          // e.data = e
          this.orderType = 3
          let ysData = ''
          if (e.isResale === 1) {
            ysData = e
            this.form.id = ysData.id // 商品id
            this.form.stock = ysData.stock // 商品库存
            this.form.breedmount.min = ysData.minBuy
            // this.form.breedmount.max = ysData.stock // 最大可买
            this.form.breedmount.step = ysData.minIncr // 增多少
            this.form.breedmount.unit = ysData.unit // 数量单位
            this.form.breedregion = ysData.name // 品种名
            this.form.breedprice.price = ysData.price // 价格

            const res = await getQuickBuySkuList(this.form.id)
            // console.log(res)
            for (const i of res.data.data) {
              i.spData = JSON.parse(i.spData)
            }
            this.form.guigeList = res.data.data // 规格
            this.form.guigeValue = this.form.guigeList[0]
            console.log(this.form.guigeValue)
            // this.options
            // for (const j of this.form.guigeList) {
            //   let flag = false
            //   // console.log(j.spData)
            //   for (const i of this.options) {
            //     if (j.spData[1].value === i.value.spData[1].value) {
            //       // i.stock += j.stock
            //       flag = true
            //       break
            //     }
            //   }
            //   if (!flag) {
            //     this.options.push({
            //       value: j,
            //       label: j.spData[1].value,
            //       children: []
            //     })
            //   }
            // }
            // for (const j of this.form.guigeList) {
            //   for (const i of this.options) {
            //     if (j.spData[1].value === i.label) {
            //       i.children.push({
            //         value: j,
            //         label: j.spData[0].value
            //       })
            //     }
            //   }
            // }
            // console.log(this.options)
            this.selectGuigeValue()

            // if (ysData.minBuy > this.form.guigeValue.stock) {
            //   this.form.breedmount.max = this.form.guigeValue.stock
            // }
            this.form.breedprice.unit = ysData.currencySymbol // 货币单位
            this.form.stateregion = ysData.deliveryPlace // 仓库名字
            // this.form.withholdingTime = '' // 预提时间
            // this.form.deliveryTime = ysData.deliveryTime
            this.thisData = ysData
          } else { //  右下角
            ysData = e.data
            console.log(ysData)
            this.form.id = ysData.product.id // 商品id
            this.form.breedmount.step = ysData.product.minIncr // 增多少
            this.form.breedmount.min = ysData.product.minBuy // 最小买多少

            const res = await getQuickBuySkuList(this.form.id)
            // console.log(res)
            for (const i of res.data.data) {
              i.spData = JSON.parse(i.spData)
            }
            this.form.guigeList = res.data.data // 规格
            this.form.guigeValue = this.form.guigeList[0]
            // for (const j of this.form.guigeList) {
            //   let flag = false
            //   // console.log(j.spData)
            //   for (const i of this.options) {
            //     if (j.spData[1].value === i.value.spData[1].value) {
            //       // i.stock += j.stock
            //       flag = true
            //       break
            //     }
            //   }
            //   if (!flag) {
            //     this.options.push({
            //       value: j,
            //       label: j.spData[1].value,
            //       children: []
            //     })
            //   }
            // }
            // for (const j of this.form.guigeList) {
            //   for (const i of this.options) {
            //     if (j.spData[1].value === i.label) {
            //       i.children.push({
            //         value: j,
            //         label: j.spData[0].value
            //       })
            //     }
            //   }
            // }
            this.selectGuigeValue()
            this.form.breedmount.unit = ysData.product.unit // 数量单位
            this.form.breedregion = ysData.product.name // 品种名
            // this.form.breedprice.price = ysData.product.price // 价格
            this.form.breedprice.unit = ysData.product.currencySymbol // 货币单位
            this.form.stateregion = ysData.pmsSiteEntity.name // 仓库名字
            this.thisData = ysData.product // 保存所有信息
            return
          }
          // console.log(e)
          console.log(this.thisData)
          ysData = null
        } else { // 转售快捷买入
          this.orderType = 2
          this.form.id = e.pmsResaleProduct.id // 商品id
          this.form.stock = e.pmsResaleProduct.stock // 商品库存
          this.form.breedmount.max = e.pmsResaleProduct.stock // 最大可买
          this.form.breedmount.step = e.product.minIncr // 增多少
          this.form.breedmount.min = e.product.minBuy // 最小买多少
          this.form.breedregion = e.pmsResaleProduct.productName || e.name // 品种名
          this.form.breedprice.price = e.pmsResaleProduct.resalePrice || e.price // 价格
          this.form.breedprice.unit = e.product.currencySymbol // 货币单位
          this.form.stateregion = e.pmsResaleProduct.pmsSiteEntity.name || e.deliveryPlace // 仓库名字
          e.skuStockList[0].spData = JSON.parse(e.skuStockList[0].spData) // 规格格式化
          this.form.guigeValue = e.skuStockList[0].spData[1].value // 规格
          this.form.withholdingTime = e.pmsResaleProduct.takeProductTime.substring(0, 10) || '' // 预提时间
          this.thisData = e
          // this.form.deliveryTime = '' // 交货日
        }
      } else {
      }

      // this.form.breedprice.unit = e.unit
    },
    // 预售选择规格
    async selectGuigeValue () {
      // console.log(this.form.breedmount.min < this.form.breedmount.max)
      this.changeGuigeValue()
      this.form.breedprice.price = this.form.guigeValue.price
      this.form.stock = this.form.guigeValue.stock
      this.form.breedmount.max = this.form.guigeValue.stock
      // console.log(this.form.breedmount.min > this.form.breedmount.max)
      // console.log(this.form.breedmount)
      if (this.form.breedmount.min > this.form.breedmount.max) {
        this.form.breedmount.mount = this.form.breedmount.max
      } else {
        this.form.breedmount.mount = this.form.breedmount.min
      }

      // const res = await getQuickBuySkuList(this.form.id)
      // console.log(res)
    },
    changeGuigeValue () {
      console.log(this.form.guigeValue.spData[0].value)
      this.form.deliveryTime = this.form.guigeValue.spData[0].value
      this.form.breedmount.max = this.form.guigeValue.stock
    },
    // 仓库发生改变触发该方法
    selectStateValue (e) {
      // 给选择的仓库属性 赋值
      // console.log(e)
      // this.form.stateregion = e.wareName
      // this.form.breedprice.price = e.price
      // 选择后下面会进行查询
    },
    // 时间处理方法
    modeTime: (time) => {
      return time.substring(0, 10)
    },
    // 提交 传参
    async submitdata (data) {
      this.form.loading = true
      console.log(data)
      // console.log(toRaw(this.$props.boxdata.datalist))

      // const tbBuy = toRaw(this.$props.boxdata.datalist)
      console.log(this.thisData)
      const postdata = [{
        productId: data.id, // 商品id
        productSkuId: this.form.guigeValue.id, // 规格id
        productQuantity: data.breedmount.mount, // 数量
        orderType: this.orderType // 订单类型0：正常，1：秒杀，2：转售，3：预售
      }]
      console.log(postdata)
      // 点击买入

      // http.post('/mall-order/order/resaleGenerateOrder', postdata, true)
      //   .then(res => {
      //     console.log(res)
      //     if (res.data.code === 200) {
      //       this.orderSn = res.data.data.orderSn || res.data.data[0].orderSn
      //       this.payAmount = res.data.data.payAmount || res.data.data[0].payAmount
      //       this.showBuy = true // 弹出支付
      //       this.password = ''

      //       this.form.loading = false
      //     }
      //     this.form.loading = false
      //   }).catch(err => {
      //     console.log(err)
      //   })
      if (data.breedmount.mount <= 0) {
        ElMessage.warning({
          message: '库存不足，无法购买!'
        })
        this.form.loading = false
        return
      }

      http.post('/mall-order/order/quickBuyGenerateOrder', postdata, true)
        .then(res => {
          console.log(res)

          if (res.data.code === 200) {
            ElMessage.success({
              message: res.data.msg
            })
            this.orderSn = res.data.data.orderSn || res.data.data[0].orderSn
            this.payAmount = res.data.data.payAmount || res.data.data[0].payAmount
            this.showBuy = true // 弹出支付
            this.password = ''

            this.form.loading = false
          } else {
            ElMessage.warning({
              message: res.data.msg
            })
          }
          this.form.loading = false
        }).catch(err => {
          console.log(err)
          ElMessage.error({ message: '请重新登录' })
        })

      // centerDialogVisible = false
      // datacop.data.form.loading = false
    },
    subConfirmGoods () { // 支付方法
      const loading = this.$loading({
        lock: true,
        text: '支付中'
      })
      // console.log(this.datalist)
      if (/^\d{6}$/.test(this.password)) {
        const password = this.password
        const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
        const md5password = this.$md5(`${capitalAccount}${password}`)
        let postData = {
          orderSn: toRaw(this.orderSn),
          password: md5password
        }
        let url = ''
        if (this.orderType === 2) {
          url = '/mall-order/order/resaleOrderPay' // 转售
          postData = {
            orderSn: toRaw(this.orderSn),
            password: md5password
          }
        } else {
          url = '/mall-order/order/datePresaleOrderPay' // 预售
          postData = {
            orderSns: toRaw(this.orderSn),
            password: md5password
          }
        }
        console.log(postData)
        http.post(url, postData, false)
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
              // ElMessage.success({
              //   message: res.data.msg
              // })
              this.password = ''
              this.orderSn = ''
              this.payAmount = ''
              this.form.breedmount.max -= this.form.breedmount.mount
              this.showBuy = false
              loading.close()
              this.$store.state.personalNumber = 4
              this.$router.push('/personalcenter/myorder')
            } else {
              this.password = ''
              ElMessage.error({
                message: res.data.msg
              })
              loading.close()
            }
          }).catch(err => {
            console.log(err)
          })
      } else {
        loading.close()
        ElMessage.error({ message: '请输入正确的6位支付密码' })
      }
    },
    readContract (code) {
      const fileUrl = code === 1
        ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E7%8E%B0%E8%B4%A7%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
        : code === 2
          ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%BD%AC%E5%94%AE%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
          : code === 3
            ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E9%A2%84%E5%94%AE%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
            : code === 4
              ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%B8%E6%98%93%E7%BB%86%E5%88%99.pdf'
              : 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%A8%E9%87%8F%E6%A0%87%E5%87%86.pdf'
      window.open(fileUrl)
    }
  },
  mounted () {
    this.saveBreed_name()
    this.selectBreedValue(this.$props.boxdata.datalist)
    // this.selectStateValue(this.$props.boxdata.datalist)
  },
  props: ['boxdata'],
  setup (props, ctx) {
    // console.log(props.boxdata)
    // 路由
    // const router = use()
    var centerDialogVisible = ref(true)
    const datacop = getCurrentInstance()

    // 时间限制  只能在规定时间选择
    const pickerOptions = {
      disabledDate: (time) => {
        // 此条为设置禁止用户选择今天之前的日期，不包含今天。
        if (props.boxdata.datalist.name === 'ys') {
          return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000))
        } else {
          return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000)) ||
          time.getTime() < new Date(datacop.data.form.canTime.withholdingBeginTime) - (24 * 60 * 60 * 1000) ||
          time.getTime() > new Date(datacop.data.form.canTime.withholdingFinishTime)
        }
      }
    }
    // 关闭弹窗操作
    const remove = () => {
      ctx.emit('removethis', false)
    }
    return {
      // firstData,
      centerDialogVisible,
      pickerOptions,
      remove
      // showTime,
    }
  }
}
</script>
<style lang="scss">
.quickbuydlog{
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        .el-dialog__header{
          height: 50px;
          padding: 0 20px;
          border-bottom: 1px solid #EAEAEA;
          .el-dialog__title{
            text-align: center;
            width: 100%;
            color: #333333;
            line-height: 50px;
          }
        }
        .el-dialog__body{
          padding: 30px 95px 0;
          .el-form{
            .el-form-item{
              margin-bottom: 20px;
              .el-form-item__label{
                // width: 50px ;
                // text-align: left;
                text-align-last: justify;
                text-align: left;
                color: #999999;
              }
              .el-form-item__content{
                flex: 1;
                display: flex;
                align-items: center;
                .el-select,.block{
                  width: 100%;
                  .el-date-editor{
                    width: 100%;
                  }
                }
                span{
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                }
                p{
                  color: #999999;
                }
                .maxp{
                  margin-left: 10px;
                  color: #999999;
                }
                .pay{
                  font-size: 20px;
                  color: #C6824E;
                }
                .timerclass{
                  color: #333333;
                }
              }
            }
            .tiaoyue{
              .tiaokuan{
                color: #C6824E;
              }
            }
          }
        }
        .el-dialog__footer{
          padding-bottom: 40px;
          .dialog-footer{
            .el-button{
              width: 80px;
            }
            .el-button--primary{
              margin-left: 20px;
              height: 32px;
              background: linear-gradient(0deg, #280E01 0%, #673000 100%);
            }
          }
        }
      }
    }
  }
}
</style>
