<template>
  <div id="table_title">
    <span class="title">{{this.state.datalist.select.name||'名称'}}</span>
  </div>
    <div id="table" @click="handle($event)"
    v-if="update">
      <div id="table_head">
        <tr v-for="(i,index) in state.datalist.title" :key="index">
          <th :class="index==0?'head1':''">{{i}}</th>
        </tr>
      </div>
      <vue-seamless-scroll
          :data="state.datalist.cont"
          :class-option="optionHover"
          class="seamless-warp">
        <div id="table_body">
          <table>
            <tr class="row"
            v-for="(k,index1) in state.datalist.cont" :key="index1"
            :data-code="this.state.datalist.title[0]=='仓库'?k.id+'市':k.subjectId"
            :id="k.id"
            @click="prdId(k)">
            <!-- 避免名字过长遮盖数据  移入显示完整数据 -->
              <!-- <el-tooltip :content="this.state.datalist.title[0]=='仓库'?k.name:k.productName"
              placement="left" effect="light"> -->
                <td class="pinzhongname">
                  {{this.state.datalist.title[0]=='仓库'?k.name:k.productName}}
                </td>
              <!-- </el-tooltip> -->
              <td>{{this.state.datalist.title[0]=='仓库'?k.count:
                this.state.datalist.title[2]=='数量'?k.product.currencySymbol+k.resalePrice+'/'+k.product.unit:k.price}}</td>
              <!-- :class="addcolor(k.priceLimit||k.countryCode)" -->
              <td
              :class="addcolor(k.priceLimit||k.increaseRange)"
              >{{this.state.datalist.title[0]=='仓库'?k.increaseRange+'%':
              this.state.datalist.title[2]=='数量'?k.stock:k.priceLimit+'%'}}</td>
            </tr>
          </table>
        </div>
      </vue-seamless-scroll>
    </div>
</template>

<script>
// import { ElMessage } from 'element-plus'

import { onMounted, reactive, ref, toRaw } from 'vue-demi'
import emitter from '../tools/bus.js'
// import Http from '../api/httptwo'

export default {
  name: 'Table',
  data () {
    return {
      state: reactive({
        datalist: ''
      }),
      dataMAX: ref(0),
      chartData: reactive({
        coffee: '',
        wareName: '',
        wareid: ''
      }),
      update: true,
      num: ref(null),
      // productId 会需要！
      productId: ref(null),
      coffeeId: ref('')
    }
  },
  computed: {
    optionHover () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    // 颜色设置
    addcolor (k) { // k.priceLimit||k.countryCode
      if (Number(k) > 0) {
        // this.num = 1
        return 'red'
      }
      if (Number(k) === 0) {
        // this.num = 0
        return 'gray'
      }
      if (Number(k) < 0) {
        // this.num = 2
        return 'green'
      }
      return ''
    },
    prdId (k) {
      // console.log(k)
      this.productId = k.id
    },
    handle (e) {
      // var coffeeId = ''
      console.log(e)
      // for (let i = 0; i < e.path.length; i++) {
      if (e.target.className === 'row') {
        // console.log(e.path[i])
        this.coffeeId = e.target.dataset.code || e.target.firstChild.innerHTML
        // console.log(coffeeId)
        // break
      } else if (e.target.className === 'pinzhongname') {
        // console.log('可能会被触发吧？？？')
        // this.coffeeId = e.target.innerHTML
        // }
      }
      console.log(this.coffeeId)
      this.isCityOrVariety(this.coffeeId)
      this.update = false
      this.$nextTick(() => {
        this.update = true
      })
      // coffeeId = null
    },
    isCityOrVariety (coffeeId) {
      if (coffeeId === undefined) {
        // if (this.productId !== '') {
        return null
        // }
        // ElMessage({
        //   message: '请选择正确的区域!',
        //   type: 'warning'
        // })
      } else {
        this.chartAndTableData(coffeeId)
        // console.log(coffeeName)
        if (this.state.datalist.select !== '') {
          // 点击 给弹出的底框一个展示的效果  到Map页面
          // emitter.emit('dataMap', { coffeeId, isShow: true })
        } else {
          // emitter.emit('dataMap', { coffeeId, isShow: false })
        }
      }
    },

    // 给 底部弹出的chartShowt组件 提供数据
    chartAndTableData (toChart) {
      console.log(toChart)
      // console.log(this.productId)
      // 判断点击的是不是某个数据 如果是直接点,会传入某某市(某某仓)
      // 再根据 仓库或者是某某品种,判断填入数据
      if (typeof toChart === 'string') {
        if (toChart.charAt(toChart.length - 1) === '市') {
          this.chartData.wareName = this.state.datalist.select.name
          this.chartData.wareid = this.state.datalist.select.id
          this.chartData.coffee = toChart
          let areaId = toRaw(this.chartData)
          // console.log(toChart)

          emitter.emit('dataMap', { areaId, isShow: false })
          areaId = null
        } else { // 这是点商品进入的操作
          if (this.state.datalist.select) { // 判断是否首先选择了城市
            let showData = this.state.datalist.select
            // 反人类传参  站点跟内容对调
            emitter.emit('dataMap', { productId: this.productId, coffee: showData.id, wareid: toChart, isShow: false })
            showData = null
            return
          }
          // console.log(this.state.datalist.select)
          // console.log(toChart)
          this.chartData.wareName = this.state.datalist.select.name
          this.chartData.coffee = toChart // 可能是id
          let coffeeId = toChart
          // 传给Map组件
          emitter.emit('dataMap', { coffeeId, isShow: false })
          // emitter.emit('tableClick', this.chartData)
          coffeeId = null
        }
      } else {
        // console.log('000000000')
        if (toChart !== undefined) {
          // console.log(toChart)
          emitter.emit('coffeeClick', toChart)
        }
        this.chartData.wareName = toChart.wareName
        this.chartData.coffee = toChart.variety
      }
    }
  },

  created () {
    this.state.datalist = this.$props.datalist
    console.log(this.state.datalist)
    this.dataMAX = this.state.datalist.cont.length
    // this.isCityOrVariety('')

    emitter.on('varDataCity', (varDataCity) => {
      // console.log(varDataCity)
      // varDataCity.select = varDataCity.select.split(',')
      this.state.datalist = varDataCity
      console.log(this.state.datalist)
      this.update = true
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.state.datalist = this.$props.datalist
    })
  },
  // 接收datamap传值
  props: ['datalist'],
  setup (props, ctx) {
    // const datacop = getCurrentInstance()
    // datacop.data.state.datalist = props.datalist
    onMounted(() => {
      // console.log('2222')
    })
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
#table_title{
  width: 100%;
  background: #122149;
  .title{
    margin: 0 20px;
    margin-top: -10px;
    display: block;
    box-sizing: border-box;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 39px;
    text-align: center;
    border-bottom: 1px solid #4f2b18;
  }
}
#table{
  // height: 330px;
  background: #122149;
  color: #FFFFFF;
    #table_head{
      display: flex;
      padding: 15px 20px;
      tr{
        width: 100%;
        text-align: center;
        display: flex;
        th{
          flex: 1;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #AC703B;
        }
        .head1{
          min-width: 90px;
        }
      }
    }
  .seamless-warp{
    // ************ 410px
    height: 210px;
    overflow: hidden;
    #table_body{
      table{
        display: flex;
        flex-flow: column;
        tr{
          height: 30px;
          flex-shrink: 0;
          padding: 5px 20px;
          box-sizing: border-box;
          display: flex;
          td{
            font-size: 12px;
            line-height: 20px;
            flex: 1;
            text-align: center;
          }
          .pinzhongname{
            min-width: 90px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .red{
            background:red;
          }
          .green{
            background: #00C200;
          }
          .gray{
            background: #999999;
          }
        }
        tr:hover{
          cursor: pointer;
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}
</style>
