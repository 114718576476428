<template>
<div class="quickbuydlog">
  <el-dialog
    v-model="centerDialogVisible"
    title="快捷卖出"
    width="560px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    center
  >
    <el-form ref="form" :model="form" label-width="120px">
      <!-- 品种change改变时，触发查询仓库接口  返回该品种所有的仓库 -->
      <el-form-item label="品 种:" label-width="80px">
        <span class="saleclass">{{form.breedregion}}</span>
      </el-form-item>
      <el-form-item label="仓 库:" label-width="80px">
        <span class="saleclass">{{form.stateregion}}</span>
      </el-form-item>
      <el-form-item label="买入价格:" label-width="80px">
        <span class="timerclass">{{form.currencySymbol+form.buyPrice+'/'+form.unit}}</span>
      </el-form-item>
      <el-form-item label="出售单价:" label-width="80px">
        <el-input v-model="form.salePrice" maxlength="8" placeholder=" " clearable />
      </el-form-item>
      <el-form-item label="数 量:" label-width="80px">
        <el-input-number
        :step="form.breedmount.step"
        v-model="form.breedmount.mount"
        size="small"
        :min="form.breedmount.max<form.breedmount.min?form.breedmount.max:form.breedmount.min"
        :max="form.breedmount.max"/>
        <p class="maxp">最大可卖 {{form.breedmount.max}}</p>
      </el-form-item>
      <el-form-item label="结束时间:" label-width="80px">
        <!-- <el-input v-model="form.toSellDay"></el-input> -->
          <div class="block">
            <el-date-picker
            v-model="form.toSellDay"
            type="date"
            placeholder="转售到期日"
            :disabledDate="pickerOptions.disabledDate"
            value-format="YYYY-MM-DD"
            >
            </el-date-picker>
          </div>
      </el-form-item>
    </el-form>
    <!-- <div>{{boxdata}}</div> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
        size="small"
        @click="centerDialogVisible = false,remove()"
        >取消</el-button>
        <el-button type="primary"
        size="small"
        :loading="loading"
        @click="submitdata(this.form)"
        >确定</el-button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import { ref, getCurrentInstance, reactive } from 'vue'
import tomessage from '../tools/tomessage.js'
// import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import http from '../api/http.js'
// import { useRouter } from 'vue-router'
// import moment from 'moment'

export default {
  name: 'MessageBoxQuickBuy',
  data () {
    return {
      form: reactive({
        orderSn: '',
        // 当前选择的品种名  初始赋值
        breedregion: '',
        // 当前选择的仓库名
        stateregion: '',
        // 买入价格
        buyPrice: '',
        // 出售价格
        salePrice: '',
        currencySymbol: '' || '￥',
        // 数量，最大数量
        breedmount: {
          step: 1,
          mount: 0,
          max: 10,
          min: 1
        },
        // 单位
        unit: '',
        // 转售到期日
        toSellDay: '',
        // 截至时间
        timeOver: ''
      }),
      loading: false
    }
  },
  methods: {
    saveBreed_name (saleData) {
      // 获取所有信息的数据渲染到页面
      console.log(saleData)
      this.form.orderSn = saleData.orderSn // 订单编号
      this.form.breedregion = saleData.omsOrderItem.productName // 品种
      // this.form.stateregion = saleData.pmsProduct.deliveryPlace // 仓库
      this.form.stateregion = saleData.pmsSiteEntity.name // 仓库
      this.form.buyPrice = saleData.omsOrderItem.productPrice // 买入价格
      // this.form.currencySymbol = saleData.pmsProduct.currencySymbol || '￥' // 货币类型
      this.form.currencySymbol = saleData.pmsProduct.currencySymbol || '￥' // 货币类型
      this.form.unit = saleData.pmsProduct.unit // 重量单位
      this.form.breedmount.max = saleData.omsOrderItem.productQuantity - saleData.resaleNum // 最大可卖数量>>当前库存量
      this.form.breedmount.min = saleData.pmsProduct.minBuy // 最小购买量
      this.form.breedmount.step = saleData.pmsProduct.minIncr // 最小增量
      this.form.timeOver = saleData.presellTimeScope.split(',')[0].slice(-10) // 转售到期时间限制
    }
  },
  mounted () {
    this.saveBreed_name(this.$props.boxdata.datalist)
  },
  props: ['boxdata'],
  setup (props, ctx) {
    // console.log(props.boxdata)
    // const router = useRouter()
    var centerDialogVisible = ref(true)
    const datacop = getCurrentInstance()

    // 关闭弹窗操作
    const remove = () => {
      ctx.emit('removethis', false)
    }

    const pickerOptions = {
      disabledDate: (time) => {
        // 此条为设置禁止用户选择今天之前的日期，不包含今天。
        return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000)) ||
          time.getTime() > (new Date(datacop.data.form.timeOver) - (24 * 60 * 60 * 1000))
      }
    }
    // 点击确定按钮后 验证出售价格，结束日期
    const submitdata = (dataform) => {
      const data = props.boxdata.datalist
      const mess = tomessage.fromvalidate('quickSale', {
        salePrice: datacop.data.form.salePrice,
        toSellDay: datacop.data.form.toSellDay
      })
      ElMessage(mess.data)
      console.log(data)
      if (mess.data.type === 'warning') {
        // remove() // 关闭弹窗
        datacop.data.loading = true

        const postdata = {
          orderSn: dataform.orderSn,
          resalePrice: Number(dataform.salePrice), // 卖出价格
          quantity: dataform.breedmount.mount, // 销量>>卖出数量
          endTime: dataform.toSellDay // 转售到期日
        }

        http.post('/mall-order/resale/insert', postdata)
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
              ElMessage.success({
                message: res.data.msg
              })
              datacop.data.form.breedmount.max -= res.data.data.stock
              datacop.data.form.breedmount.mount = 0
              setTimeout(() => {
                // router.push('/map')
                window.location.reload()
              }, 1000)
            } else {
              ElMessage.error({
                message: res.data.msg
              })
            }
            datacop.data.loading = false
          }).catch(err => {
            console.log(err)
          })
      } else {
        datacop.data.loading = false
      }
      // console.log(data)
    }
    return {
      // firstData,
      centerDialogVisible,
      pickerOptions,
      remove,
      submitdata
    }
  }
}
</script>
<style lang="scss">
.quickbuydlog{
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        .el-dialog__header{
          height: 50px;
          padding: 0 20px;
          border-bottom: 1px solid #EAEAEA;
          .el-dialog__title{
            float: left;
            color: #333333;
            line-height: 50px;
          }
        }
        .el-dialog__body{
          padding: 30px 95px 0;
          .el-form{
            .el-form-item{
              margin-bottom: 20px;
              .el-form-item__label{
                // width: 50px ;
                // text-align: left;
                text-align-last: justify;
                text-align: left;
                color: #999999;
              }
              .el-form-item__content{
                flex: 1;
                display: flex;
                align-items: center;
                .el-select{
                  width: 100%;
                }
                span{
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                }
                p{
                  color: #999999;
                }
                .maxp{
                  margin-left: 10px;
                  color: #999999;
                }
                .pay{
                  font-size: 20px;
                  color: #C6824E;
                }
                .timerclass{
                  color: #333333;
                }
                .block{
                  flex: 1;
                  background: #FFFFFF;
                  border-radius: 4px;
                  .el-input--prefix{
                    width: 100%;
                    height: 40px;
                    align-items: center;
                    .el-input__inner{
                      height: 40px;
                    }
                    input::-webkit-input-placeholder{
                      color: #999999;
                    }
                    .el-input__prefix-inner{
                      .el-icon{
                        color: #C6824E;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .el-dialog__footer{
          padding-bottom: 40px;
          .dialog-footer{
            .el-button{
              width: 80px;
            }
            .el-button--primary{
              margin-left: 20px;
              height: 32px;
              background: linear-gradient(0deg, #280E01 0%, #673000 100%);
            }
          }
        }
      }
    }
  }
}
</style>
