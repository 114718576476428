<template>
<div id="page">
  <div id="search">
    <ul>
      <li v-for="(item,index) in headernav" :key="index"
      @click="this.$router.push(item.path)">
        {{item.text}}
      </li>
      <li @click="toSell"><a>{{$t('m.Header.Switch_merchant_side')}}</a></li>
    </ul>
    <div class="logosearch">
      <div id="logo">
        <img src="../../assets/logo2.png" alt="" @click="this.$router.push('/')">
      </div>
      <div class="cut">
        <div id="sear">
          <el-input id="searin" v-model="searValue" :placeholder="$t('m.search.commodity')"></el-input>
          <button class="sou" @click="sou">{{$t('m.search.search')}}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="quickbuy">
    <DataMap @toBoxs="(data)=>{tobox(data)}"/>
  </div> -->
  <div class="title_line">
    <div id="toutiao" class="bottom_tout">
      <div class="toutiao_cont">
          <span id="title">{{toudata.tou+'：'}}</span>
          <vue-seamless-scroll
          :data="toudata.cont"
          :class-option="optionHover"
          class="seamless-warp">
            <div id="cont">
              <p v-for="(item,index) in toudata.cont" :key="index" @click="totoutiao(item)">{{item.title}}</p>
            </div>
          </vue-seamless-scroll>
        </div>
    </div>
    <h3>
      <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/coffeeBean2.png" alt="">
      : {{$t('m.map.Click_on_the_city_to_view_the_goods_under_the_city')}}
    </h3>
  </div>
  <div id="showmap">
    <!-- *********** -->
    <div id="right">
      <baidu-map></baidu-map>
      <!-- <div id="table_time">
        <YushouTable/>
      </div> -->
      <!-- v-show起到收起隐藏，不干扰其他元素正常运作 -->
      <div class="vivify" v-if="this.isShow"
      :class="this.isShow?'swoopInLeft':'swoopOutLeft'"
      id="showbiao"
      >
        <ChartShow @toBoxs="(data)=>{tobox(data)}"/>
        <el-icon @click="closeclass"><Close /></el-icon>
      </div>
    </div>
    <div id="left">
      <DataMap @toBoxs="(data)=>{tobox(data)}"/>
    </div>
  </div>
  <!-- 买入卖出弹出框 -->
  <div id="showBuySale" v-if="rt">
    <!-- ！！！！！！！！！！！！！！到子组件 卖出或者买入的数据传输-->
    <component :is="toBox"
     :boxdata="datapay"
     @removethis="(k)=>{rt=k}"
     @toQuickSale="(data)=>{tobox(data)}"
     ></component>
  </div>
  <div id="footer">
    <Footer/>
  </div>
</div>
</template>

<script>
import BaiduMap from './BaiduMap'
import Footer from '../index/Footer.vue'
import DataMap from './DataMap.vue'
import YushouTable from './YushouTable.vue'
import ChartShow from './ChartShow.vue'
import MessageBoxQuickBuy from '../../components/MessageBoxQuickBuy.vue'
import MessageBoxQuickSale from '../../components/MessageBoxQuickSale.vue'
import MessageTableBuy from '../../components/MessageTableBuy.vue'
import MessageTableSale from '../../components/MessageTableSale.vue'

import { Close } from '@element-plus/icons'
import { ElMessage } from 'element-plus'
import { reactive, ref, getCurrentInstance } from 'vue'
import emitter from '../../tools/bus'
import http from '../../api/http.js'
import i18n from '@/tools/i18n.js'
import { getStore } from '../../tools/storage'
const { t } = i18n.global

const headernav = [
  { icon: '', text: t('m.Header.home_page'), path: '/' },
  { icon: '', text: t('m.Header.login_again'), path: '/loginandregister' },
  { icon: '', text: t('m.Header.mobile_terminal'), path: '/' }
  // { icon: '', text: '切换商家端', path: '/merchantworkbench' }
]
// 查询今日头条接口
const getTodayHeadline = (data) => {
  return http.get('/mall-portal/mall/post/queryPopularPosts', data)
}
export default {
  name: 'Map',
  data () {
    return {
      varDataCity: reactive({
        title: ['仓库', '数量', '涨幅'],
        cont: [{}],
        select: {
          name: '',
          id: ''
        }
      }),
      rt: ref(true),
      isShow: ref(false),
      toBox: ref(null),
      datapay: reactive({}),
      toudata: reactive({
        tou: this.$t('m.search.Today_Headlines'),
        cont: []
      }), // 头条
      searValue: ref('')
    }
  },
  computed: {
    optionHover () {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  components: {
    BaiduMap,
    Footer,
    DataMap,
    YushouTable,
    ChartShow,
    MessageTableBuy,
    MessageTableSale,
    MessageBoxQuickBuy,
    MessageBoxQuickSale,
    Close
  },
  methods: {
    // 关闭页面方法
    closeclass (data) {
      this.isShow = !data
    },
    // 去买入或者卖出  盒子
    tobox (value) {
      console.log(value)
      this.rt = true
      this.toBox = value.goto
      this.datapay = value
      return this.toBox
    },
    // 接收卖出的传值给快捷卖出结算！！！
    toQuickS () {
      emitter.on('toQuickSale', (sale) => {
        console.log(sale)
      })
    },
    async initdata () { // 初始化头条数据
      const Headline = await getTodayHeadline({
        pageNum: 1,
        pageSize: 3,
        postOrInformation: 2
      })
      // console.log(Headline)
      if (Headline.data.code === 200) {
        this.toudata.cont = Headline.data.data.list
      }
    },
    totoutiao (item) {
      console.log(item)
      this.$router.push({
        path: '/coffee/informationArticle',
        query: {
          id: item.id
        }
      })
    },
    sou () {
      this.$router.push({
        path: '/search',
        query: { sedata: '0', data: this.searValue }
      })
      emitter.emit('searchHeaderBase', { sedata: '0', data: this.searValue })
    },
    toSell () {
      if (!JSON.parse(getStore('usermessage'))) {
        ElMessage.warning({
          message: '没有检测到登录信息，请登录后操作',
          duration: 2000
        })
        return
      }
      if (JSON.parse(getStore('usermessage')).type === 0) {
        ElMessage.error('没有相关权限')
        return ''
      } else {
        this.$router.push('/merchantworkbench')
        setTimeout(() => {
          window.location.reload() // 页面重新加载
        }, 100)
      }
    }
  },
  mounted () {
    this.initdata()
    // this.citysNameinit()
    // 接收 Table组件传过来的值，给isShow设置为true
    emitter.on('dataMap', (canshu) => {
      console.log(canshu)
      if (canshu.isShow === false) {
        // 根据选择的品种id或者 城市  传入数据  并返回仓库数据
        if (canshu.coffeeId) { // 点击的是咖啡就会传入coffeeId
          http.get(`/mall-portal/home/getSiteListBySubjectId/${canshu.coffeeId}`)
            .then(res => {
              console.log(res)
              if (res.data.data.length === 0) {
                return
              }
              // console.log(this.varDataCity)
              this.varDataCity.cont = res.data.data
              this.varDataCity.select.name = res.data.data[0].specialSubjectName // 第一条
              this.varDataCity.select.id = canshu.coffeeId
              // console.log(this.varDataCity)
              emitter.emit('varDataCity', this.varDataCity)

              const citysName = []
              for (const i of res.data.data) {
                citysName.push({
                  citys: i.name,
                  img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/coffeeBean2.png',
                  stock: i.count + i.unit
                })
              }
              // console.log(citysName)
              emitter.emit('cityName', { citys: citysName })
            }).catch(err => {
              console.log(err)
            })
        } else if (canshu.coffeeId === '') {
          return ''
        } else { // 这里coffee==城市id
          // console.log('在这')
          canshu.areaId.coffee = canshu.areaId.coffee.substring(0, canshu.areaId.coffee.length - 1)
          console.log(canshu.areaId)
          // 发送数据给chartShow组件 console.log(this.chartData)
          emitter.emit('tableClick', canshu.areaId)
          this.isShow = true
        }
      } else {
        if (canshu.coffee && canshu.wareid) {
          emitter.emit('tableClick', canshu)
        }
        this.isShow = canshu.isShow
      }
    })
  },
  setup (props, ctx) {
    const datacop = getCurrentInstance()

    // console.log(datacop)
    // 表格买入弹出 快捷买入信息
    emitter.on('toBoxs', (data) => {
      console.log(data)
      // 关闭当前弹窗
      datacop.data.rt = true
      datacop.data.toBox = data.goto
      datacop.data.datapay = data
      // return datacop.data.toBox
    })
    return {
      headernav
    }
  }
}
</script>

<style lang="scss" scoped>
#page{
  #search{
    height: 120px;
    background: #ffffff;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .logosearch{
      display: flex;
      // height: 100%;
      z-index: 99;
      justify-content: center;
      align-items: center;
      #logo{
        img{
          cursor: pointer;
          margin-right: 80px;
          z-index: 9999;
        }
      }
      .cut{
        padding:0 10px;
        #sear{
          width: 659px;
          flex: 1;
          display: flex;
          align-items: center;
          #searin{
            // border: 1px solid #FFFFFF;
            box-sizing: border-box;
            background: 0;
            flex: 1;
            height: 40px;
            padding: 0 15px;
            outline: none;
            border: 1px solid;
            border-image: linear-gradient(0deg, #280E01, #673000) 10 10;
          }
          #searin::-webkit-input-placeholder {
            color: #999999;
          }
          #searin:-moz-placeholder {
            color: #999999;
          }
          #searin:-ms-input-placeholder {
            color: #999999;
          }
          #searin::-ms-input-placeholder{
            color: #999999;
          }
          .sou{
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #FFFFFF;
            background: linear-gradient(0deg, #280E01 0%, #673000 100%);
            width: 120px;
            height: 40px;
          }
        }
      }
    }
  }
  // .quickbuy{
  //   width: 920px;
  //   margin:0 auto;
  //   background: url('../../static/imges/LRbg.jpg') no-repeat;
  // }
  .title_line{
    background: #F2EFED;
    line-height: 30px;
    display: flex;
    flex-flow: column;
    h3{
      width:1200px;
      margin: 0 auto;
      color: red;
      display: flex;
      align-items: center;
      background: #ffffff;
      height: 50px;
      img{
        height: 40px;
        width: 40px;
      }
    }
    #toutiao{
      background: #ffffff;
      border-top: 1px solid #F2EFED;
      border-bottom: 1px solid #F2EFED;
      .toutiao_cont{
        margin: 0 auto;
        width: 1200px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        #title{
          color: #3B1C01;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
        }
        .seamless-warp{
          // flex: 1;
          max-width: 1100px;
          height: 40px;
          overflow: hidden;
          #cont{
            text-overflow:ellipsis;
            white-space:nowrap;
            overflow:hidden;
            p{
              width: 100%;
              height: 40px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
  #showmap{
    width: 100%;
    height: 700px;
    padding: 0 0 20px; // 可调整整体高度
    min-width: 20px;
    background: #F2EFED;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    #left{
      z-index: 999;
      // background: #280E01;
      width: 280px;
      height: 100%;
    }
    #right{
      // z-index: 999;
      width: 920px;
      height: 100%;
      // background: #280E01;
      position: relative;
      #table_time{
        z-index: 5;
        position: absolute;
        right: 0;
        top: 340px;
      }
      #showbiao{
        z-index: 99;
        width: 880px;
        height: 353px;
        position: absolute;
        top: 307px;
        // box-sizing: border-box;
        margin-right: 40px;
        margin-bottom: 40px;
        i{
          font-size: 20px;
          color: #C6824E;
          cursor: pointer;
          position: absolute;
          top: 16px;
          right:20px;
        }
      }
      .swoopOutLeft{
        transform: scaleX(1.5) translate3d(-770px,0,0) !important;
      }
    }
  }
  #footer{
    width: 100%;
    background: #280E01;
  }
}
</style>
