<template>
  <footer>
    <div>
      <ul>
        <li @click="readContract(1)">{{$t("m.search.transaction_rules")}}<span>|</span></li>
        <li @click="readContract(2)">{{$t("m.search.Instructions_for_membership")}}<span>|</span></li>
        <!-- <li @click="readContract(3)">公告<span>|</span></li> -->
        <li @click="readContract(4)">{{$t("m.search.user_manual")}}</li>
      </ul>
    </div>
    <div>
      <!-- <p>{{$t("m.index.company_address")}}：{{$t("m.index.company_address_content")}} {{$t("m.index.fixed_line_telephone")}}：0512-83512580</p> -->
      <p>{{$t("m.index.company_address")}}：{{$t("m.index.company_address_content")}}</p>
    </div>
    <div>
      <p>{{$t("m.index.ccdt")}} {{$t("m.index.All_right_reserved")}}
        <a href="https://beian.miit.gov.cn/" target="_blank"> 苏ICP备2022001170号-1 </a>
        <a href="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/icp.pdf" target="_blank"> {{$t("m.index.Business_license")}}</a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32058302003278">&ensp;<img src="../../../public/static/备案图标.png"/> 苏公网安备 32058302003278号</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    readContract (code) {
      const fileUrl = code === 1
        ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%B8%E6%98%93%E7%BB%86%E5%88%99.pdf'
        : code === 2
          ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf'
          : code === 3
            ? ''
            : 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/e%E8%B4%B8%E7%BD%91%E7%94%A8%E6%88%B7%E6%89%8B%E5%86%8C.pdf'
      window.open(fileUrl)
    }
  },
  setup () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
footer{
  // width: 1920px;
  width: 100%;
  // height: 120px;
  display: flex;
  flex-flow: column;
  div{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    ul{
      display: inline-block;
      li{
        float: left;
        cursor: pointer;
        span{
          margin: 0 10px;
        }
      }
    }
    p{
      width: 100%;
      display: inline-block;
      border-top: 1px solid #a8a8a846;
      a{
        transition: all .3s;
        cursor: pointer;
        color: #FFFFFF;
        img{
          // display: block;
          position: relative;
          top: 4px;
        }
      }
      a:hover{
        color: #c88756;
      }
    }
  }
}
</style>
